@import url(https://fonts.googleapis.com/css2?family=Caesar+Dressing&family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Poppins:wght@400;500;600;700;800&family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
.cloud-1 {
    position: absolute;
    top: -100px;
    left: -450px;
    width: 1500px;
    z-index: 3;
    -webkit-animation: cloud-left 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: cloud-left 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-2 {
    position: absolute;
    top: 400px;
    right: -500px;
    width: 1500px;
    z-index: 4;
    -webkit-animation: cloud-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: cloud-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-3 {
    position: absolute;
    top: 350px;
    left: -500px;
    width: 1500px;
    z-index: 5;
    -webkit-animation: cloud-left 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: cloud-left 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-4 {
    position: absolute;
    top: -80px;
    right: -600px;
    width: 1500px;
    z-index: 6;
    -webkit-animation: cloud-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: cloud-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-small1 {
    position: absolute;
    top: 200px;
    left: 250px;
    width: 500px;
    z-index: 3;
    -webkit-animation: cloud-left 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: cloud-left 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    /* animation-delay: 0.6s; */
}

.cloud-small2 {
    position: absolute;
    top: 400px;
    right: 250px;
    width: 500px;
    z-index: 3;
    -webkit-animation: cloud-right 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: cloud-right 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    /* animation-delay: 0.6s; */
}

/* Keyframe */

@-webkit-keyframes cloud-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1100px);
        transform: translateX(-1100px);
        opacity: 0;
    }
}

@keyframes cloud-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1100px);
        transform: translateX(-1100px);
        opacity: 0;
    }
}

@-webkit-keyframes cloud-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1100px);
        transform: translateX(1100px);
        opacity: 0;
    }
}

@keyframes cloud-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1100px);
        transform: translateX(1100px);
        opacity: 0;
    }
}

/* mobile */

@-webkit-keyframes cloud-left-mobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-700px);
        transform: translateX(-700px);
        opacity: 0;
    }
}

@keyframes cloud-left-mobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-700px);
        transform: translateX(-700px);
        opacity: 0;
    }
}

@-webkit-keyframes cloud-right-mobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(700px);
        transform: translateX(700px);
        opacity: 0;
    }
}

@keyframes cloud-right-mobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(700px);
        transform: translateX(700px);
        opacity: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .cloud-1 {
        top: 0px;
        left: -200px;
        width: 800px;
    }

    .cloud-2 {
        top: 300px;

        right: -150px;
        width: 800px;
    }

    .cloud-3 {
        top: 300px;

        left: -300px;
        width: 800px;
    }

    .cloud-4 {
        top: 0px;
        right: -300px;
        width: 800px;
    }

    .cloud-small1 {
        top: 100px;
        left: 350px;
        width: 300px;
    }

    .cloud-small2 {
        top: 250px;

        right: 350px;
        width: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .cloud-1 {
        top: 100px;
        left: -60px;
        width: 300px;

        -webkit-animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;

                animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-2 {
        top: 200px;

        right: -50px;
        width: 300px;

        -webkit-animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;

                animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-3 {
        top: 220px;

        left: -100px;
        width: 300px;

        -webkit-animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;

                animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-4 {
        top: 100px;
        right: -100px;
        width: 300px;
        -webkit-animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
                animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-small1 {
        top: 170px;
        left: 100px;
        width: 100px;

        -webkit-animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;

                animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-small2 {
        top: 210px;

        right: 130px;
        width: 100px;
        -webkit-animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
                animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

@font-face {
    font-family: 'coder';
    src: local('coder'), url(/static/media/font.617a0a92.woff) format('woff');
}

body {
    margin: 0;
    font-family: 'Overlock', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* cursor: grab; */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
} */

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html {
    background-color: '#4C4C4C';
    scroll-behavior: smooth;
}

div {
    /* -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none; */
}

