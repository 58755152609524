.cloud-1 {
    position: absolute;
    top: -100px;
    left: -450px;
    width: 1500px;
    z-index: 3;
    animation: cloud-left 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-2 {
    position: absolute;
    top: 400px;
    right: -500px;
    width: 1500px;
    z-index: 4;
    animation: cloud-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-3 {
    position: absolute;
    top: 350px;
    left: -500px;
    width: 1500px;
    z-index: 5;
    animation: cloud-left 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-4 {
    position: absolute;
    top: -80px;
    right: -600px;
    width: 1500px;
    z-index: 6;
    animation: cloud-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.cloud-small1 {
    position: absolute;
    top: 200px;
    left: 250px;
    width: 500px;
    z-index: 3;
    animation: cloud-left 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    /* animation-delay: 0.6s; */
}

.cloud-small2 {
    position: absolute;
    top: 400px;
    right: 250px;
    width: 500px;
    z-index: 3;
    animation: cloud-right 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    /* animation-delay: 0.6s; */
}

/* Keyframe */

@keyframes cloud-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1100px);
        transform: translateX(-1100px);
        opacity: 0;
    }
}

@keyframes cloud-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1100px);
        transform: translateX(1100px);
        opacity: 0;
    }
}

/* mobile */

@keyframes cloud-left-mobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-700px);
        transform: translateX(-700px);
        opacity: 0;
    }
}

@keyframes cloud-right-mobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(700px);
        transform: translateX(700px);
        opacity: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .cloud-1 {
        top: 0px;
        left: -200px;
        width: 800px;
    }

    .cloud-2 {
        top: 300px;

        right: -150px;
        width: 800px;
    }

    .cloud-3 {
        top: 300px;

        left: -300px;
        width: 800px;
    }

    .cloud-4 {
        top: 0px;
        right: -300px;
        width: 800px;
    }

    .cloud-small1 {
        top: 100px;
        left: 350px;
        width: 300px;
    }

    .cloud-small2 {
        top: 250px;

        right: 350px;
        width: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .cloud-1 {
        top: 100px;
        left: -60px;
        width: 300px;

        animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-2 {
        top: 200px;

        right: -50px;
        width: 300px;

        animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-3 {
        top: 220px;

        left: -100px;
        width: 300px;

        animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-4 {
        top: 100px;
        right: -100px;
        width: 300px;
        animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-small1 {
        top: 170px;
        left: 100px;
        width: 100px;

        animation: cloud-left-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    .cloud-small2 {
        top: 210px;

        right: 130px;
        width: 100px;
        animation: cloud-right-mobile 15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }
}
